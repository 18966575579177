<template>
  <HeroBg
    heroTitle="Hire exceptional talents without the hassle"
    heroDesc="Build and manage global remote teams effortlessly with CareerBuddy. We <br /> handle everything - hiring, onboarding, compensation, regulations, etc"
    heroBtnText="Start Hiring"
    heroBtnLink="/contact"
    heroImg="hero7.png"
  />

  <Customers />

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full lg:w-[72vw]">
      <div class="py-[5rem] pt-6">
        <div class="px-[24px] md:px-0">
          <h2 class="text-center mb-8 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">How the outsourcing process works</h2>
        </div>

        <StepsLight />
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw]">
      <div class="py-[5rem]">
        <h2 class="text-center mb-8 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">Our Global Outsourcing Offering</h2>

        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 pb-5">
          <div class="mb-4 md:mb-0 bg-[#FFE5A3] rounded-lg p-8">
            <p class="font-bold text-[18px] md:text-[25px] mb-3 leading-[100%]">Employer of Record (EOR)</p>
            <p class="text-[14px] md:text-[17px] leading-[120%] text-brand-black mb-8">
              We handle payroll, benefits, taxes, and compliance for your Nigeria employees. You remain the official employer.
            </p>

            <div class="mb-6">
              <a href="/contact" class="rounded-2xl px-6 py-4 text-center bg-brand-black text-white">Start Hiring</a>
            </div>
          </div>

          <div class="mb-4 md:mb-0 bg-[#BEFFE0] rounded-lg p-8">
            <p class="font-bold text-[18px] md:text-[25px] mb-3 leading-[100%]">Professional Employer Organization (PEO)</p>
            <p class="text-[14px] md:text-[17px] leading-[120%] text-brand-black mb-8">
              We provide comprehensive HR services on an outsourced basis by supplying staff recruited and contracted by ourselves to you.
            </p>

            <div class="mb-6">
              <a href="/contact" class="rounded-2xl px-6 py-4 text-center bg-brand-black text-white">Start Hiring</a>
            </div>
          </div>

          <div class="mb-4 md:mb-0 bg-[#F3E0FF] rounded-lg p-8">
            <p class="font-bold text-[18px] md:text-[25px] mb-3 leading-[100%]">Embedded Recruiter</p>
            <p class="text-[14px] md:text-[17px] leading-[120%] text-brand-black mb-8">
              An end to end Recruiter managed by us is placed on your team, embedded in your slack channel and communicates with your hiring manager.
            </p>

            <div class="mb-6">
              <a href="/contact" class="rounded-2xl px-6 py-4 text-center bg-brand-black text-white">Start Hiring</a>
            </div>
          </div>

          <div class="mb-4 md:mb-0 bg-[#E0E3FF] rounded-lg p-8">
            <p class="font-bold text-[18px] md:text-[25px] mb-3 leading-[100%]">Embedded Sourcer</p>
            <p class="text-[14px] md:text-[17px] leading-[120%] text-brand-black mb-8">
              An expert Sourcer works fully embedded into your Talent Acquisition team to source and screen top talents.
            </p>

            <div class="mb-6">
              <a href="" class="rounded-2xl px-6 py-4 text-center bg-brand-black text-white">Learn more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Testimonial />

  <div class="flex justify-center bg-brand-black">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw] flex items-center justify-center">
      <div class="py-[8rem] text-white text-center">
        <h1 class="text-white text-[32px] md:text-[60px]">Ready to work together?</h1>

        <p class="text-[16px] md:text-[32px] leading-[110%] mb-[65px] text-white/50">We'll find you amazing remote employees for up to 80% less</p>
        <br class="hidden md:inline-block" />
        <div>
          <a href="/contact" class="rounded-3xl px-16 py-6 text-center bg-light-yellow text-brand-black text-[13px] md:text-[18px]">Start Hiring</a>
        </div>
      </div>
    </div>
  </div>

  <CreateAccount />
</template>

<script setup>
import Testimonial from '@/components/partials/Testimonial.vue';
import StepsLight from '@/components/partials/StepsLight.vue';
import Customers from '@/components/partials/Customers.vue';
import HeroBg from '@/components/partials/HeroBg.vue';
import CreateAccount from '@/components/partials/CreateAccount.vue';
import { useMeta } from '@/utils/meta';

useMeta();
</script>
