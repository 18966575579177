<template>
  <div>
    <div class="bg-white rounded-2xl p-8 mb-6">
      <div class="md:flex justify-between items-center gap-x-16 px-8 py-4">
        <div class="mb-3 md:mb-0">
          <span class="px-4 py-3 bg-[#C1FFE9]/[.79] rounded-md inline-block text-[16px] text-brand-black/50 font-semibold mb-3">STEP 1 of 3</span>
          <h1 class="text-brand-black my-3 mb-1 font-bold text-[18px] md:text-[28px]">Matching</h1>
          <p class="leading-[130%] text-[14px] md:text-[16px] text-brand-black">
            Extend job offers to matched talent and onboard.We recruit a Growth Assistant (GA) to fit your exact needs, working hours, etc. This step
            takes about 14 days.
          </p>

          <div class="mt-5">
            <a
              :href="$employerUrl + '/register'"
              class="rounded-3xl px-6 md:px-16 py-4 text-center bg-brand-black text-white inline-flex items-center"
            >
              <span class="mr-2">Get Started</span>
              <img src="@/assets/icons/arrow-right.svg" alt="" />
            </a>
          </div>
        </div>

        <img src="@/assets/images/team.png" alt="" />
      </div>
    </div>

    <div class="bg-white rounded-2xl p-8 mb-6">
      <div class="md:flex justify-between items-center gap-x-16 px-8 py-4">
        <div class="mb-3 md:mb-0">
          <span class="px-4 py-3 bg-[#C1FFE9]/[.79] rounded-md inline-block text-[16px] text-brand-black/50 font-semibold mb-3">STEP 2 of 3</span>
          <h1 class="text-brand-black my-3 mb-1 font-bold text-[18px] md:text-[28px]">Get matched with vetted talents</h1>
          <p class="leading-[130%] text-[14px] md:text-[16px] text-brand-black">
            Meet top quality talent that matches your hiring request. Conduct interviews & further assessments if necessary.
          </p>

          <div class="mt-5">
            <a
              :href="$employerUrl + '/register'"
              class="rounded-3xl px-6 md:px-16 py-4 text-center bg-brand-black text-white inline-flex items-center"
            >
              <span class="mr-2">Get Started</span>
              <img src="@/assets/icons/arrow-right.svg" alt="" />
            </a>
          </div>
        </div>

        <img src="@/assets/images/hiring.png" alt="" />
      </div>
    </div>

    <div class="bg-white rounded-2xl p-8">
      <div class="md:flex justify-between items-center gap-x-16 px-8 py-4">
        <div class="mb-3 md:mb-0">
          <span class="px-4 py-3 bg-[#C1FFE9]/[.79] rounded-md inline-block text-[16px] text-brand-black/50 font-semibold mb-3">STEP 3 of 3</span>
          <h1 class="text-brand-black my-3 mb-1 font-bold text-[18px] md:text-[28px]">Hire without overhead</h1>
          <p class="leading-[130%] text-[14px] md:text-[16px] text-brand-black">
            Extend job offers to matched talent and onboard. We recruit a Growth Assistant (GA) to fit your exact needs, working hours, etc. This step
            takes about 14 days.
          </p>

          <div class="mt-5">
            <a
              :href="$employerUrl + '/register'"
              class="rounded-3xl px-6 md:px-16 py-4 text-center bg-brand-black text-white inline-flex items-center"
            >
              <span class="mr-2">Get Started</span>
              <img src="@/assets/icons/arrow-right.svg" alt="" />
            </a>
          </div>
        </div>

        <img src="@/assets/images/globe.png" alt="" />
      </div>
    </div>
  </div>
</template>
